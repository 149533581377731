/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #0073DD;
  --secondary-color: #338FE3;
}

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-secondary {
  background-color: var(--secondary-color);
}
.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.border-primary {
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}

.gradient-box {
  background: linear-gradient(to bottom, #0074DC, #01B54F);
}

@layer base {
  h1, h2 {
    font-size: initial;
  }

  ol > li {
    @apply list-decimal font-bold;
  }
  ol > ol > li {
    @apply list-decimal list-inside font-normal;
  }

  ul > li {
    @apply list-disc list-inside ml-4;
  }

  ul > ul > li {
    @apply list-inside ml-8 text-xs;
    list-style-type: circle;
  }
}

.image-opacity {
  mask-image:
          linear-gradient(to bottom, transparent 0%, black 15%) /* Top fade */,
          linear-gradient(to top, transparent 0%, black 15%) /* Bottom fade */,
          linear-gradient(to right, transparent 0%, black 15%) /* Left fade */,
          linear-gradient(to left, transparent 0%, black 15%); /* Right fade */;
  mask-composite: intersect; /* Intersect all the gradients to create the cut-out effect */
  -webkit-mask-composite: source-in; /* Ensure it works in WebKit browsers */
}
