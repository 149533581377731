.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 100px;
}

.swiper-pagination-bullet {
    background-color: #F8BCA6;
}

.swiper-pagination-bullet-active {
    width: 40px;
    border-radius: 4px;
    background-color: #F05A24;
}

.dynamic-size-div {
    width: 100%;              /* Dynamic width */
    padding-bottom: 50%;       /* Height is 50% of the width */
}

/*media screen for mobile*/
@media screen and (max-width: 768px) {
    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 30px;
    }
}
