.navigate-button {
    position: absolute;
    z-index: 1000;
    top: 50%;
    width: 32px;
    height: 32px;
}

.button-prev {
    left: -40px;
}

.button-next {
    right: -40px;
}
